<template>
    <modal ref="modalAdvertencia" titulo="Advertencia" :adicional="textBoton" @adicional="accion">
        <div class="row mx-0 j-center mb-3">
            <div class="col-auto text-center text-general f-15">
                {{ mensaje }}
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props:{
        mensaje:{
            type:String,
            default:"Sin datos"
        },
        textBoton:{
            type:String,
            default:""
        }
    },
    data(){
        return {
           
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAdvertencia.toggle();
        },
        accion(){
            this.$refs.modalAdvertencia.toggle();
            this.$emit('accion',this.textBoton)
        }
    }
}
</script>
